<template>
  <div class="flex flex-row justify-center w-ful">
    <b-overlay :show="showOverlay || initial">
      <template #overlay>
        <div
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="w-full text-center"
        >
          <div v-if="initial">
            Fetching
            <b-spinner
              class="mr-1"
              variant="success"
              small
              label="Small Spinner"
            ></b-spinner>
          </div>
          <div v-else-if="message == 'processed'">
            <b-spinner
              class="mr-1"
              variant="success"
              small
              label="Small Spinner"
            ></b-spinner>
            Watching for exports -- No new Exports
          </div>
          <div v-else-if="message == 'generating'">
            Generating -- Refresh for updates
          </div>
          <div v-else-if="message == 'timeout'">
            Timeout -- Try again in a few minutes
          </div>
        </div>
      </template>
      <b-card no-body class="max-w-6xl p-1 w-full">
        <b-row
          v-if="activeDownloads.length <= 0 && trays.length > 0"
          class="w=full mx-1 border rounded-lg p-1 mb-1"
        >
          <b-col cols="8">
            <b-row class="my-1">
              <div class="my-1 text-lg font-bold ml-3">Mailing Details</div>
            </b-row>
            <b-row class="my-1">
              Total Trays:
              <span class="text-lg font-bold"> {{ tTrays }}</span>
            </b-row>

            <b-row class="my-1">
              Total Letters:
              <span class="ml-1 text-lg font-bold"> {{ tLetters }} </span>
            </b-row>
            <b-row class="my-1">
              Total Postage Cost:
              <span class="ml-1 text-lg font-bold">{{ tCost }}</span>
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-button class="float-right align-bottom" @click="preDownload()">
              GENERATE AND DOWNLOAD MAILING
            </b-button>
          </b-col>
        </b-row>
        <b-row class="w=full ml-1 mb-1">
          <b-col>
            <b-row class="w-full border rounded-lg mb-1">
              <div
                v-if="activeDownloads.length > 0"
                class="mb-1 mt-1 text-lg font-bold ml-1"
              >
                Generating Exports ...
              </div>
              <div v-else class="mb-1 mt-1 text-lg font-bold ml-1">
                No Exports Being Generated
              </div>
              <b-table
                :items="activeDownloads"
                :fields="activeDownloadFields"
                class="w-full border rounded-lg"
              >
                <template #cell(status)>
                  <b-spinner
                    class="mr-1"
                    variant="success"
                    small
                    label="Small Spinner"
                  ></b-spinner>
                </template>
              </b-table>
            </b-row>

            <b-row class="w-full border rounded-lg mb-1">
              <div v-if="trays.length > 0" class="my-1 text-lg font-bold ml-1">
                Pending Orders
              </div>
              <div v-else class="my-1 text-lg font-bold ml-1">
                No Pending Orders
              </div>
              <b-table
                :items="trays"
                :fields="fields"
                class="w-full border rounded-lg"
              >
                <template #row-details="row">
                  <b-table
                    :items="row.item.value"
                    :fields="fieldsSub"
                    class="border rounded-lg"
                  ></b-table>
                  {{ row.item.value.total }}
                </template>
              </b-table>
            </b-row>

            <b-row class="w-full border rounded-lg">
              <div
                v-if="days.length > 0"
                class="mb-1 mt-1 text-lg font-bold ml-1"
              >
                Completed Order Details
              </div>
              <div v-else class="mb-1 mt-1 text-lg font-bold ml-1">
                No Completed Orders
              </div>

              <b-table
                :items="days"
                :fields="fields2"
                class="w-full border rounded-lg"
              >
                <template #row-details="row">
                  <b-table
                    :items="row.item.value"
                    :fields="fields2Sub"
                    class="border rounded-lg"
                  >
                    <template #cell(status)>
                      <span>
                        <feather-icon icon="CheckIcon" class="mr-1" />
                      </span>
                    </template>
                  </b-table>

                  {{ row.item.value.total }}
                </template>
              </b-table>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCol,
  BRow,
  BButton,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    BCol,
    BRow,
    BCard,
    BButton,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      downloadRequested: false,

      waiting: 0,
      blockCall: false,
      time: 0,
      isRunning: false,
      interval: null,
      busy: false,
      show: false,
      stop: false,
      message: false,
      simulate: true,
      initial: true,
      showOverlay: false,
      downloadURL: "",
      tTrays: "",
      tLetters: "",
      tCost: "",
      activeDownloads: [],
      activeDownloadFields: [
        {
          key: "status",
          label: "",
        },
        {
          key: "business_user",
          label: "Business / User",
        },
        {
          key: "start_time",
          label: "Start Time",
        },
        {
          key: "total_letters",
          label: "Total Letters",
        },
        {
          key: "completed",
          label: "Total Completed",
        },
        {
          key: "progress",
          label: "Remaining",
        },
      ],
      fieldsSub: [
        {
          key: "dollar",
          label: "Total Cost",
        },
        {
          key: "line1",
          label: "Name / Location",
        },
        {
          key: "totals",
          label: "Letters",
        },
        {
          key: "barcode",
          label: "Tray Barcode",
        },
        {
          key: "dest_zip",
          label: "Destination",
        },
      ],
      fields: ["tray_type"],
      fields2: [
        {
          key: "date",
          label: "Export Date",
        },
      ],
      fields2Sub: [
        {
          key: "status",
          label: "status",
        },
        {
          key: "biz_name",
          label: "Business / User",
        },
        {
          key: "totals",
          label: "Total Exports",
        },
      ],

      trays: [],
      days: [],
    };
  },
  watch: {
    busy(newValue) {
      if (!newValue && this.downloadRequested) {
        this.stop = true;
        this.download();
      }
    },
    message(newValue, oldValue) {
      if (newValue) {
        this.showOverlay = true;
      } else if (oldValue && !newValue) {
        this.simulate = true;
        this.showOverlay = false;
      } else {
        this.showOverlay = false;
      }
    },
    activeDownloads(newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        this.activeDownloads = newValue;
        this.simulate = true;
      }
    },
    async time(newValue) {
      if (newValue > 3 && !this.stop) {
        if (!this.busy) {
          this.getExportHistoryStats();
          this.show = true;
          await setTimeout(() => console.log(``), 2000);
          this.show = false;
        }
        this.time = 0;
      }
    },
  },
  created() {
    this.getExportHistoryStats();
    this.toggleTimer();
  },
  methods: {
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    preDownload() {
      this.downloadRequested = true;
    },
    download() {
      var l1 = this;
      l1.downloadRequested = false;
      l1.blockCall = true;
      l1.toggleTimer();
      l1.$AuthAxios.instance
        .post("/api-access/exports/mailings/", {})
        .then(function (res) {
          l1.message = "processed";
          l1.simulate = false;
          l1.busy = false;
          l1.showOverlay = false;
          l1.initial = false;

          var link = document.createElement("a");

          link.setAttribute("download", "file");

          link.href = res.data.url;

          document.body.appendChild(link);

          link.click();

          link.remove();
          window.location.reload();
        })
        .catch((error) => {
          l1.message = "error";
          console.log(error);
        });
    },
    toastNotify(props) {
      this.$toast({
        component: ToastificationContent,
        props: props,
      });
    },
    async getExportHistoryStats() {
      await setTimeout(() => console.log(``), 1000);
      if (!this.blockCall && !this.busy) {
        this.busy = true;
        var l1 = this;
        l1.showStatsModal = true;

        l1.$AuthAxios.instance
          .get("/api-access/exports/mailings/", {
            params: { simulate: l1.simulate },
          })
          .then(function (res) {
            if (res.data.message) {
              l1.message = res.data.message;
              l1.simulate = false;
              l1.busy = false;
              l1.initial = false;
            } else {
              l1.message = false;
              l1.activeDownloads = res.data.active_downloads;
              l1.days = res.data.days;
              if (l1.simulate) {
                l1.trays = res.data.trays;
                l1.tTrays = res.data.t_trays;
                l1.tLetters = res.data.t_letters;
                l1.tCost = res.data.t_cost;
              }
              l1.simulate = false;
              l1.busy = false;

              l1.initial = false;
            }
          })
          .catch((error) => {
            l1.message = "error";
            l1.simulate = false;
            l1.tTrays = "No Customer Exports";
            l1.tLetters = "No Customer Exports";
            l1.tCost = "No Customer Exports";
            l1.busy = false;
            l1.initial = false;
            console.log(error);
          });
        return null;
      }
    },
  },
};
</script>


